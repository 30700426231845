<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="标题" prop="title">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="分类" prop="pid">
        <el-select v-model="info.pid" placeholder="请选择">
          <el-option
              v-for="item in menuList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片" prop="picid">
        <SelectImgs :selectNum="1" :selectData="[info.img]" :selectFunc="picChoose" :idx="1"></SelectImgs>
        <div style="margin-top: 40px">200*110px</div>
      </el-form-item>
      <el-form-item label="地址" prop="link_url">
        <el-input v-model="info.link_url" placeholder="请输入地址"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="ordering">
        <el-input v-model="info.ordering" placeholder="请输入序号，倒序"><template slot="append">倒序排列</template></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
import WangEdit from "../../components/WangEdit";
export default {
  data() {
    return {
      menuList: [],
      info: {
        id: 0,
        pid: '',
        img: '',
        title: '',
        link_url: ''
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        pid: [
          { required: true, message: '请选择类目', trigger: 'blur' }
        ],
      }
    };
  },
  created() {
    this.getMenuList()
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  components: {
    SelectImgs
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    picChoose(item) {
      this.info.img = item.pic
    },
    getMenuList() {
      var that = this
      this.$api.merchant.linkMenuIndex({},function (res) {
        if(res.errcode == 0) {
          that.menuList = res.data
        }
      })
    },
    getInfo() {
      var that = this
      this.$api.merchant.linkInfo({id: that.info.id}, function (res) {
        if(res.errcode == 0) {
          that.info = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          this.$api.merchant.linkEdit(param,function (res) {
            if(res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
</style>
